﻿import CapshineApp from "../capshine-assets/capshine-app";
/*import PaymentPlans from "./PaymentPlans";*/
import { CapshineQuery, PaymentPlans, PaymentThankyou, Payments, MyClub, WatchVideoScript, LearningVideoMCQScript, PaymentUpgradeRequestScript, VoiceRecordingScript, VideoScript, VideoLessonsScript, WathedVideoLessonsScript, WatchShortVideoScript, CodeOfConduct, TalkTimeManagementToolScript, WatchShortVideoHLSPlayerScript, WatchShortVideosInIOSScript, WatchShortVideoScriptInIOSYt, TalktimeManageSession, LiveSessionManagementToolScript, CoachDashboardScript, WatchJobInterviewVideoScript, JobInterviewCourseScript, JobInerviewCourseVideoQuizScript, WatchJobInterviewYoutubeVideoScript, JobInterviewCoursePreviewScript, ShortVideosViewAllScript, JobInterviewWatchVideoScript, CoachFeedbackManagementScript,JobInterviewQuizAssessmentScript,CourseAssessmentScript } from "../capshine-assets/capshine";
import { createApp } from 'vue';
import { CapshineDailyActivity, CapshineDailyActivityResponse, CapshineDailyActivityDetails, CapshineEditProfile, CapshineMyAccount, CapshineHome, CapshineActivity, bindGA, CapshineTalktime, IntroJS, CSMainAPIs } from "../capshine-assets/capshine";
/*import { getJSDocAugmentsTag } from "typescript";*/
import { Device, DeviceInfo } from '@capacitor/device';

window.Capshine = {
	capshineApp: null,
	ensureApp: function () {
		if (!this.capshineApp) {
			this.capshineApp = new CapshineApp();
			this.capshineApp.Register({});
			console.log("register running ");
		}
	}
};

window.ScreenActions =
{
	showSamplePage: function () {
		window.Capshine.ensureApp();
		//	var Sample = window.Capshine.capshineApp.InitPanel('Sample', {});
		//	var club = 
		//	alert(6)
	},
	showClubRegistrationForm: function () {
		/*window.Capshine.capshineApp.InitPanel('Club', {});*/
	}


}
const urlParams = new URLSearchParams(window.location.search);
const playNo = urlParams.get('playNo');
const isLoggedIn = getCookie("capshine_login")

window.Capshine.PageTypes = {
	Payment: "Payment",
	PaymentPlans: "PaymentPlans",
	PaymentSummary: "PaymentSummary",
	PaymentThankyou: "PaymentThankyou",
	PrivacyPolicy: "PrivacyPolicy",
	Faq: "Faq",
	Terms: "Terms",
	AboutUs: "AboutUs",
	Home: "Home",
	HomeWithoutLogin: "HomeWithoutLogin",
	DailyActivity: "DailyActivity",
	Activity: "Activity",
	Quiz: "Quiz",
	JumbledSentence: "JumbledSentence",
	FillTheGap: "FillTheGap",
	DailyActivityResponse: "DailyActivityResponse",
	DailyActivityDetails: "DailyActivityDetails",
	WatchVideo: "WatchVideo",
	Login: "Login",
	SignUp: "SignUp",
	EnglishAssesment: "EnglishAssesment",
	LearningPlan: "LearningPlan",
	MyAccount: "MyAccount",
	EditProfile: "EditProfile",
	MyRewards: "MyRewards",
	MySubscription: "MySubscription",
	FluencyReport: "FluencyReport",
	CoachFeedbacks: "CoachFeedbacks",
	LearningVideoMCQ: "LearningVideoMCQ",
	PaymentUpgradeRequest: "PaymentUpgradeRequest",
	VoiceRecording: "VoiceRecording",
	Videos: "Videos",
	VideoLessons: "VideoLessons",
	WatchedVideos: "WatchedVideos",
	WatchShortVideos: "WatchShortVideos",
	TalktimeSchedules: "TalktimeSchedules",
	Talktime: "Talktime",
	SignUpTalktime: "SignUpTalktime",
	TalktimeSlotBooking: "TalktimeSlotBooking",
	Meanings: "Meanings",
	TalkTimeManagementTool: "TalkTimeManagementTool",
	LiveSessionManagementTool: "LiveSessionManagementTool",
	HLSPlayer: "HLSPlayer",
	WatchShortVideosInIOS: "WatchShortVideosInIOS",
	WatchShortVideosInIOSYt: "WatchShortVideosInIOSYt",
	TalktimeManageSession: "TalktimeManageSession",
	CoachDashboard: "Coaches",
	WatchJobInterviewCourseVideo: "WatchJobInterviewCourseVideo",
	JobInterviewCourse: "JobInterviewCourse",
	JobInterviewCourseVideoQuiz: "JobInterviewCourseVideoQuiz",
	JobInterviewCoursePreview: "JobInterviewCoursePreview",
	ShortVideosViewAll: "ShortVideosViewAll",
	JobInterviewWatchVideo: "JobInterviewWatchVideo",
	CoachDashboardFeedback: "CoachDashboardFeedback",
	JobInterviewQuizAssessment: "JobInterviewQuizAssessment",

	CourseAssessment:'CourseAssessment'

}

window.Capshine.ensureApp();
bindGA.init();
bindGA.pushValues('impression', '', '');

const hdnUserEle = document.getElementById('hdnUserPid');
var userPid = 0;
if (hdnUserEle)
	userPid = hdnUserEle.value;

const noSessionPages = [
	window.Capshine.PageTypes.HomeWithoutLogin,
	window.Capshine.PageTypes.Login
]
function getCookie(name) {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}


// If user is already logged in and is visiting login page then redirect him to home page
if (noSessionPages.indexOf(document.querySelector("#hdnPageType").value) > -1 && isLoggedIn) {
	console.log("login page")

	// window.location.href = "/home"
}



// if (isLoggedIn) {
// 	const uuid = window.localStorage.getItem("session-id");
// 	const noPopupPages = [
// 		window.Capshine.PageTypes.HomeWithoutLogin,
// 		window.Capshine.PageTypes.Login,
// 		window.Capshine.PageTypes.SignUp,
// 		window.Capshine.PageTypes.PaymentUpgradeRequest,
// 		window.Capshine.PageTypes.CoachDashboard,
// 		window.Capshine.PageTypes.TalktimeManageSession,
// 		window.Capshine.PageTypes.LiveSessionManagementTool,
// 		window.Capshine.PageTypes.TalkTimeManagementTool,
// 	]
// 	const hiddenPageType = document.querySelector("#hdnPageType")?.value;
// 	const isAdminPage = noPopupPages.indexOf(hiddenPageType) > -1
// 	if(uuid) {
// 		// check if device id exists and if user is active another device
// 		CSMainAPIs.Capshine.APIs.IsCapshineSessionActive.execute({ sessionId: uuid }, async(res) => {
// 			if (res.data.showLoginPopup) {
// 				const deviceId = (await Device.getId()).uuid
// 				if (!res.data.isSessionActive) {
// 					window.localStorage.removeItem("session-id")
// 					document.cookie = "capshine_login=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=capshine.com";
// 					window.location.href = '/';
// 				} else {
// 					CSMainAPIs.Capshine.APIs.GetCapshineDeviceLogin.execute({ UserPid: userPid }, async (resp) => {
// 						// check if any other session is active
// 						const session = resp.data.filter((data, idx) => data.sessionId !== uuid && data.isLoggedIn === true)
// 						if (session.length > 0 && !isAdminPage) {
// 							const multipleDevicePopUp = document.getElementById("MultipleDeviceLoginPopUp");
// 						  if(multipleDevicePopUp){
// 							multipleDevicePopUp.classList.remove("hide");
// 							multipleDevicePopUp.classList.add("show");
// 							const logoutPopUp = document.getElementById("logoutBtnFromPopUp")
// 							const logoutFromAllDevicesPopUp = document.getElementById("logoutFromAllDevicesBtnFromPopUp")
// 							logoutFromAllDevicesPopUp.addEventListener("click", async function () {
// 								CSMainAPIs.Capshine.APIs.LogoffOtherDevices.execute({ UserPid: userPid, SessionId: uuid }, () => {
// 									multipleDevicePopUp.classList.remove("show");
// 									multipleDevicePopUp.classList.add("hide");
// 								})
// 							})
// 						}
// 							logoutPopUp.addEventListener("click", async function () {

// 								const upsertCapshineDeviceLogin = async () => {
// 									const x = await fetch('https://api.ipify.org?format=json');
// 									const { ip } = await x.json();
// 									// @ts-ignore
// 									const data = {
// 										UserPid: hdnUserEle.value,
// 										SessionId: uuid,
// 										DeviceId: (await Device.getId()).uuid,
// 										DeviceIp: ip,
// 										Source: "web",
// 										IsLoggedIn: false
// 									}
// 									return new Promise((resolve, reject) => {
// 										CSMainAPIs.Capshine.APIs.UpsertCapshineDeviceLogin.execute(data, (resp) => {
// 											resolve(resp.data)
// 										})
// 									})
// 								}
// 								await upsertCapshineDeviceLogin();
// 								window.localStorage.removeItem("session-id")
// 								document.cookie = "capshine_login=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=capshine.com";
// 								window.location.href = '/';
// 							});
// 							return
// 						}
// 					})
// 				}
// 			}
// 		})
// 	} else {
// 		// TODO: Uncomment if the login popup feature is back to active.

// 		// create new login entry for already active users.
// 		// const upsertCapshineDeviceLogin = async () => {
// 		// 	const x = await fetch('https://api.ipify.org?format=json');
// 		// 	const { ip } = await x.json();
// 		// 	const newId = crypto.randomUUID();

// 		// 	window.localStorage.setItem("session-id", newId);
// 		// 	const data = {
// 		// 		UserPid: hdnUserEle.value,
// 		// 		DeviceId: (await Device.getId()).uuid,
// 		// 		SessionId: newId,
// 		// 		DeviceIp: ip,
// 		// 		Source: "web",
// 		// 		IsLoggedIn: true
// 		// 	}
// 		// 	return new Promise((resolve, reject) => {
// 		// 		CSMainAPIs.Capshine.APIs.UpsertCapshineDeviceLogin.execute(data, (resp) => {
// 		// 			resolve(resp.data)
// 		// 		})
// 		// 	})
// 		// }
// 		// upsertCapshineDeviceLogin();
// 	}
// }



if (document.querySelector("#hdnPageType")) {
	const hiddenPageType = document.querySelector("#hdnPageType").value;
	debugger;
	switch (hiddenPageType) {
		case window.Capshine.PageTypes.PaymentPlans:
			PaymentPlans.init();
			break;
		case window.Capshine.PageTypes.PaymentSummary:
			Payments.init();
			break;
		case window.Capshine.PageTypes.Payment:
			Payments.initPage();
			break;
		case window.Capshine.PageTypes.PaymentThankyou:
			PaymentThankyou.init();
			break;
		case window.Capshine.PageTypes.Faq:
			new CapshineQuery()
				.RegisterCollapsableLabels();
			//.RegisterAccordions('faqpage');
			break;
		case window.Capshine.PageTypes.Home:
			setTimeout(() => {
				bindGA.pushValues('gtm.load', 'impression', 'pageview');
				MyClub.init(userPid, null, 'home');
				CapshineDailyActivity.init(userPid);
				CodeOfConduct.init(userPid);
				CapshineTalktime.init(userPid, 'home');
				IntroJS?.showIntroScreen(window.Capshine.PageTypes.Home);
				// Set introHighlighter background to inherit when it meets header.
			}, 1000);
			break;
		case window.Capshine.PageTypes.HomeWithoutLogin:
			CapshineHome.init();
			break;
		case window.Capshine.PageTypes.DailyActivity:
			CapshineDailyActivity.init(userPid);
			break;
		case window.Capshine.PageTypes.DailyActivityResponse:
			CapshineDailyActivityResponse.init(userPid);
			break;
		case window.Capshine.PageTypes.DailyActivityDetails:
			CapshineDailyActivityDetails.init(userPid);
			break;
		case window.Capshine.PageTypes.WatchVideo:
			bindGA.pushValues('gtm.load', 'impression', 'pageview');
			WatchVideoScript.init(userPid);
			break;
		case window.Capshine.PageTypes.EnglishAssesment:
			bindGA.pushValues('gtm.load', 'impression', 'pageview');
			window.Capshine.capshineApp.InitPanel('EnglishAssesment', {});
			break;
		case window.Capshine.PageTypes.Login:
			let isCapshineUser = getParameterByName("isCapshineUser")
			if (isCapshineUser) {
				if (isCapshineUser == 0) {
					window.Capshine.capshineApp.InitPanel('LoginWeb', {});
					break;
				}
			}

			if (!isLoggedIn) {
				window.Capshine.capshineApp.InitPanel('LoginWeb', {});
				break;
			} else {
				setTimeout(() => {
					bindGA.pushValues('gtm.load', 'impression', 'pageview');
					MyClub.init(userPid, null, 'home');
					CapshineDailyActivity.init(userPid);
					CodeOfConduct.init(userPid);
					CapshineTalktime.init(userPid, 'home');
					IntroJS?.showIntroScreen(window.Capshine.PageTypes.Home);
					// Set introHighlighter background to inherit when it meets header.
				}, 1000);
				break;
			}
		case window.Capshine.PageTypes.SignUp:
			window.Capshine.capshineApp.InitPanel('Club', { skipIntroScreen: true, isSignUpPage: true, closeSignUpPopup: () => window.history.back() });
			break;
		case window.Capshine.PageTypes.LearningVideoMCQ:
			LearningVideoMCQScript.init(userPid);
			break;
		case window.Capshine.PageTypes.PaymentUpgradeRequest:
			PaymentUpgradeRequestScript.init(userPid);
			break;
		case window.Capshine.PageTypes.LearningPlan:
			bindGA.pushValues('gtm.load', 'impression', 'pageview');
			//	CapshineDailyActivity.init();
			// MyClub.init(userPid);
			// CapshineDailyActivity.init(userPid);
			setTimeout(() => {
				MyClub.init(userPid, null, 'learningplan');
				CapshineDailyActivity.init(userPid);
			}, 1000);
			break;
		case window.Capshine.PageTypes.EditProfile:
			CapshineEditProfile.init(userPid);
			break;
		case window.Capshine.PageTypes.Activity:
			bindGA.pushValues('gtm.load', 'impression', 'pageview');
			CapshineDailyActivity.init(userPid);
			CapshineActivity.init(userPid);
			break;
		case window.Capshine.PageTypes.Quiz:
			window.Capshine.capshineApp.InitPanel('Quiz', { mcqType: 10, userPid: userPid, id: 'quiz-play-div', playNo: playNo });
		case window.Capshine.PageTypes.JumbledSentence:
			window.Capshine.capshineApp.InitPanel('JumbledSentence', { mcqType: 60, userPid: userPid, id: 'quiz-play-div', playNo: playNo });
		case window.Capshine.PageTypes.Meanings:
			window.Capshine.capshineApp.InitPanel('Meanings', { mcqType: 70, userPid: userPid, id: 'quiz-play-div', playNo: playNo });
			break;
		case window.Capshine.PageTypes.FillTheGap:
			window.Capshine.capshineApp.InitPanel('FillTheGap', { mcqType: 20, userPid: userPid, id: 'fillthegap-play-div', playNo: playNo });
			break;
		case window.Capshine.PageTypes.MyRewards:
			CapshineMyAccount.init('myrewards', userPid);
			break;
		case window.Capshine.PageTypes.MyAccount:
			bindGA.pushValues('gtm.load', 'impression', 'pageview');
			CapshineMyAccount.init('myaccount', userPid);
			break;
		case window.Capshine.PageTypes.MySubscription:
			CapshineMyAccount.init('mysubscription', userPid);
			break;
		case window.Capshine.PageTypes.FluencyReport:
			CapshineMyAccount.init('FluencyReport', userPid);
			break;
		case window.Capshine.PageTypes.CoachFeedbacks:
			CapshineMyAccount.init('CoachFeedbacks', userPid);
			break;
		case window.Capshine.PageTypes.VoiceRecording:
			setTimeout(() => {
				VoiceRecordingScript.init();
			}, 1000);
			break;
		case window.Capshine.PageTypes.Videos:
			setTimeout(() => {
				bindGA.pushValues('gtm.load', 'impression', 'pageview');
				VideoScript.init(userPid, null);
				IntroJS?.showIntroScreen(window.Capshine.PageTypes.Videos);
			}, 1000);
			break;
		case window.Capshine.PageTypes.VideoLessons:
			setTimeout(() => {
				bindGA.pushValues('gtm.load', 'impression', 'pageview');
				VideoLessonsScript.init(userPid, null);

			}, 1000);
			break;
		case window.Capshine.PageTypes.WatchedVideos:
			setTimeout(() => {
				bindGA.pushValues('gtm.load', 'impression', 'pageview');
				WathedVideoLessonsScript.init(userPid, null);
			}, 1000);
			break;
		case window.Capshine.PageTypes.WatchShortVideos:
			setTimeout(() => {
				bindGA.pushValues('gtm.load', 'impression', 'pageview');
				WatchShortVideoScript.init(userPid, null);
			}, 2000);
			break;

		case window.Capshine.PageTypes.TalktimeSchedules:
			bindGA.pushValues('gtm.load', 'impression', 'pageview');
			CapshineTalktime.init(userPid, 'talktimeschedules');
			break; ``
		case window.Capshine.PageTypes.Talktime:
			bindGA.pushValues('gtm.load', 'impression', 'pageview');
			CapshineTalktime.init(userPid, 'talktime');
			break;
		case window.Capshine.PageTypes.SignUpTalktime:
			bindGA.pushValues('gtm.load', 'impression', 'pageview');
			const talkTimeScheduleId = document.getElementById('SignUpTalktime_Panel').getAttribute('data-TalkTimeScheduleId');
			if (talkTimeScheduleId)
				window.Capshine.capshineApp.InitPanel('SignUpTalktime', { talkTimeScheduleId: talkTimeScheduleId, userPid: userPid });
			break;
		case window.Capshine.PageTypes.TalkTimeManagementTool:
			TalkTimeManagementToolScript.init(userPid);
			break;
		case window.Capshine.PageTypes.LiveSessionManagementTool:
			LiveSessionManagementToolScript.init(userPid);
			break;
		case window.Capshine.PageTypes.HLSPlayer:
			setTimeout(() => {
				WatchShortVideoHLSPlayerScript.init(userPid);
			}, 1000);
			break;
		case window.Capshine.PageTypes.WatchShortVideosInIOS:
			setTimeout(() => {
				bindGA.pushValues('gtm.load', 'impression', 'pageview');
				WatchShortVideosInIOSScript.init(userPid, null);
			}, 2000);
			break;
		case window.Capshine.PageTypes.WatchShortVideosInIOSYt:
			setTimeout(() => {
				bindGA.pushValues('gtm.load', 'impression', 'pageview');
				WatchShortVideoScriptInIOSYt.init(userPid, null);
			}, 2000);
			break;
		case window.Capshine.PageTypes.TalktimeManageSession:
			setTimeout(() => {
				TalktimeManageSession.init(userPid, 'talktimesessionmanagement');
			}, 2000);
			break;
		case window.Capshine.PageTypes.CoachDashboard:
			CoachDashboardScript.init(userPid, null);
			break;
		case window.Capshine.PageTypes.WatchJobInterviewCourseVideo:

			setTimeout(() => {
				//WatchJobInterviewVideoScript.init(userPid, null);
				WatchJobInterviewYoutubeVideoScript.init(userPid, null);
			}, 2000);
			break;
		case window.Capshine.PageTypes.JobInterviewCourse:
			setTimeout(() => {
				debugger;
				JobInterviewCourseScript.init(userPid, null);
			}, 2000);
			break;
		case window.Capshine.PageTypes.JobInterviewCourseVideoQuiz:
			setTimeout(() => {
				JobInerviewCourseVideoQuizScript.init(userPid, null);
			}, 2000);
			break;
		case window.Capshine.PageTypes.JobInterviewCoursePreview:
			setTimeout(() => {
				JobInterviewCoursePreviewScript.init(userPid, null);
			}, 2000);
			break;
		case window.Capshine.PageTypes.ShortVideosViewAll:
			setTimeout(() => {
				ShortVideosViewAllScript.init(userPid, null);
			}, 2000);
			break;
		case window.Capshine.PageTypes.CoachDashboardFeedback:
			setTimeout(() => {
				CoachFeedbackManagementScript.init(userPid, null);
			}, 2000);
			break;
			case window.Capshine.PageTypes.JobInterviewWatchVideo:
				JobInterviewWatchVideoScript.init(userPid);
			break;
		case window.Capshine.PageTypes.JobInterviewQuizAssessment:

			JobInterviewQuizAssessmentScript.init(userPid);
			break;
			case window.Capshine.PageTypes.CourseAssessment:
			CourseAssessmentScript.init(userPid);
			break;
				
	}
}
setTimeout(() => {
	var appBanner = document.getElementById('app-promotion-banner');
	if (appBanner) {
		document.querySelectorAll('[data-closeappbanner]').forEach(function (event) {
			event.addEventListener('click', () => {
				closeAppBanner();
			});
		});
		if (document.cookie.indexOf('capshine_appBanner') >= 0) {

		}
		else {
			let divvoiceRecordingPromptPopup = document.getElementById('divvoiceRecordingPromptPopup');
			if (divvoiceRecordingPromptPopup) {
				let isVoiceRecorded = divvoiceRecordingPromptPopup.getAttribute("data-isVoiceRecorded");
				if (isVoiceRecorded == "True") {
					document.getElementById('app-promotion-banner').classList.add('show');
				}
				else {
					if (document.cookie.indexOf('promptvoicerecodingpopup') >= 0) {
						document.getElementById('app-promotion-banner').classList.add('show');
					}

				}
			}
			else {
				document.getElementById('app-promotion-banner').classList.add('show');
			}
			//	document.getElementById('app-promotion-banner').classList.add('show');
		}
	}
}, 1000);
function closeAppBanner() {
	document.getElementById('app-promotion-banner').classList.remove('show');
	var myDate = new Date();
	myDate.setDate(myDate.getDate() + 1);
	document.cookie = "capshine_appBanner=; Path=/; Expires=" + myDate.toUTCString() + ";Domain=capshine.com";
}

function getParameterByName(name, url = window.location.href) {
	name = name.replace(/[\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
